import { IBLSpinLoader, api } from '@iblai/ibl-web-react-common';
import { useEffect } from 'react';

const selectDefaultApp = (apps) => {
  if (apps.length === 1) {
    return apps[0];
  }
  const app = apps.find((appObj) => appObj.app.type === 'web');
  if (app) {
    return app;
  } else {
    return apps[0];
  }
};

const Home = () => {
  useEffect(() => {
    api.ibldmfeatures.getApps({}, (data) => {
      const selectedApp = selectDefaultApp(data.results);
      window.location.href = selectedApp.app.url;
    });
  }, []);
  return (
    <>
      <IBLSpinLoader />
    </>
  );
};

export default Home;
