import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

export default function MobileSso() {
  const location = useLocation();
  const handleLogout = (queryParams) => {
    fetch(`${process.env.REACT_APP_IBL_LMS_URL}/logout`, {
      method: 'GET',
      credentials: 'include',
    }).finally(() => {
      localStorage.clear();
      window.location.href = `${
        process.env.REACT_APP_IBL_LMS_URL
      }${decodeURIComponent(
        queryParams.get('sso-url')
      )}&next=${encodeURIComponent(queryParams.get('next'))}`;
    });
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    handleLogout(queryParams);
  });

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <IBLSpinLoader size={40} containerHeight="100px" />
      <p>Initiating Authentication ...</p>
    </div>
  );
}
